@import url(https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  font-family: "Merriweather", serif;
  background-color: #131212;
}

body ::selection {
  background-color: #9b9b9b;
}

#root {
  height: 100vh;
}

.App {
  height: 100%;
}

.home {
  height: 100%;
  max-height: calc(100% - 40px);
  max-width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homeCover {
  display: flex;
  justify-content: center;
  width: 350px;
  height: auto;
  min-height: 200px;
  min-width: 100px;
  max-width: calc(100vw - 50px);
}

.homeCover img {
  border: 1px solid #f8f5e6;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.menu {
  margin-top: 30px;
}

.menu .title {
  color: #f8f5e6;
}

.menu .title h1 {
  font-size: 4rem;
  margin: 0px;
  font-weight: bold;
  cursor: default;
}

.menu .title h3 {
  color: #f1e5ad;
  font-size: 3rem;
  margin: 10px 0px;
  cursor: default;
}

.menu .links {
  display: flex;
  justify-content: center;
  color: #f7f3e1;
}

.menu .links h3 {
  font-size: 4rem;
  font-weight: bold;
  cursor: default;
}

.menu .links h3:hover {
  cursor: pointer;
  text-decoration: underline;
  color: white;
}

.readTab {
  background-color: #eeece1;
  min-height: 100%;
}

.readTab nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  background-color: #1d2536;
}

.readTab nav h1 {
  font-size: 2rem;
  font-weight: bold;
  margin: 15px;
  cursor: default;
}

.readTab nav h2 {
  font-size: 1.5rem;
  margin: 15px;
}

.readTab nav h2:hover {
  cursor: pointer;
  text-decoration: underline;
}

.readTab .arrowsinfo {
  display: none;
}

.readTab .readMain {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  min-height: 92vh;
  position: relative;
}

.readTab .readMain .poemList {
  flex-basis: 18%;
  border-right: 1px solid black;
  max-height: 100%;
  overflow: auto;
}

.readTab .readMain .poemList ul {
  padding: 10px;
}

.readTab .readMain .poemList ul li {
  padding: 3px;
}

.readTab .readMain .poemList ul li:hover {
  cursor: pointer;
  text-decoration: underline;
}

.readTab .readMain .poemList ul li.selected {
  color: #b34c44;
  font-weight: 600;
}

.readTab .readMain .book {
  flex-basis: 85%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-height: calc(100% - 40px);
  position: relative;
}

.readTab .readMain .book .page {
  display: flex;
  line-height: 1.4rem;
  max-width: 600px;
  height: 100%;
  max-height: calc(100% - 20px);
  width: 48%;
  margin: 10px;
}

.readTab .readMain .book .page div {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.readTab .readMain .book .page .CharOverride-2 {
  font-size: 1.2rem;
  margin: 10px;
  line-height: 2.5rem;
  font-weight: bold;
}

.readTab .readMain .book .page img {
  background-color: #e6e6e6;
  border: 1px solid #222222;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.readTab .readMain .pagePrevious,
.readTab .readMain .pageNext {
  position: relative;
  background: none;
  border: none;
  top: 200px;
  height: 100%;
  font-size: 3rem;
  margin: 10px;
}

.readTab .readMain .pagePrevious:not(:disabled):hover,
.readTab .readMain .pageNext:not(:disabled):hover {
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .menu .title h1 {
    font-size: 2.5rem;
    font-weight: bold;
    cursor: default;
  }
  .menu .title h3 {
    font-size: 1.5rem;
    cursor: default;
  }
  .menu .links h3 {
    font-size: 3rem;
    font-weight: bold;
    margin: 10px 0px;
    cursor: default;
    color: white;
  }
  .menu .links h3:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  .readTab {
    min-height: 100%;
  }
  .readTab nav h1 {
    font-size: 1.7rem;
  }
  .readTab nav h2 {
    font-size: 1.3rem;
  }
  .readTab .arrowsinfo {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 15px 0px;
  }
  .readTab .readMain {
    margin: auto;
    max-height: unset;
    overflow: visible;
  }
  .readTab .readMain .poemList {
    display: none;
    font-size: 0.6rem;
  }
  .readTab .readMain .poemList ul {
    padding: 5px;
  }
  .readTab .readMain .arrowsHidden {
    opacity: 0;
  }
  .readTab .readMain .book {
    margin: auto;
    padding-bottom: 10px;
    flex-basis: 100%;
    max-width: calc(100vw - 20px);
    flex-direction: column;
  }
  .readTab .readMain .book .page {
    font-size: 0.875rem;
    line-height: 1.4rem;
    width: 100%;
    margin: 10px auto;
  }
  .readTab .readMain .pagePrevious,
  .readTab .readMain .pageNext {
    background-color: #eeece1;
    color: black;
    transition: opacity 0.5s;
    height: auto;
    padding: 3px;
    border-radius: 5px;
    top: 48%;
    position: fixed;
    font-size: 2rem;
    margin: 5px;
    z-index: 9999;
  }
  .readTab .readMain .pagePrevious {
    left: 0px;
  }
  .readTab .readMain .pageNext {
    right: 0px;
  }
}
/*# sourceMappingURL=App.css.map */
